import NextImage from 'next/image'
import React from 'react'
import styled from 'styled-components'

import { Text, Tag, ButtonLink, Container } from 'common/UI'
import { HighlightCardStoryblok } from 'common/types'
import { getAnchorFromCmsLink, getImageAttributes } from 'common/utils/content'
import { addAlphaToColor } from 'common/utils/style'

type Props = {
  block: HighlightCardStoryblok
}

export const HighlightCard = ({ block, ...rest }: Props): JSX.Element => {
  const { tag, title, description, link, media } = block
  return (
    <HighlightCardContainer {...rest}>
      <Card>
        <CardInformation>
          <div>
            {tag && <Tag>{tag}</Tag>}
            <Text as="p" variant="title/large" css={{ marginTop: '1rem' }}>
              {title}
            </Text>
          </div>
          <div>
            <Text as="p" variant="twenty">
              {description}
            </Text>

            {link?.length && link[0] && link[0].link && (
              <Cta
                rel={getAnchorFromCmsLink(link[0].link).rel}
                href={getAnchorFromCmsLink(link[0].link).href}
                target={getAnchorFromCmsLink(link[0].link).target}
                variant="ghost"
                rightIcon="arrow-right"
              >
                {link[0].label}
              </Cta>
            )}
          </div>
        </CardInformation>

        {media?.filename && (
          <ImageHolder>
            <Image
              alt={
                getImageAttributes(media, '0x0/smart/filters:format(webp)').alt
              }
              src={
                getImageAttributes(media, '0x0/smart/filters:format(webp)').src
              }
              width={
                getImageAttributes(media, '0x0/smart/filters:format(webp)')
                  .width
              }
              height={
                getImageAttributes(media, '0x0/smart/filters:format(webp)')
                  .height
              }
            />
          </ImageHolder>
        )}
      </Card>
    </HighlightCardContainer>
  )
}

const HighlightCardContainer = styled(Container)`
  padding: 5rem 1.25rem;

  ${({ theme }) => theme.media.lg} {
    padding: 5rem;
  }
`

const Card = styled.div`
  display: flex;
  flex-direction: column;

  background-color: ${({ theme }) =>
    addAlphaToColor(theme.colors.foreground.default, 5)};

  border-radius: 1rem;

  ${({ theme }) => theme.media.lg} {
    flex-direction: row;
    justify-content: space-between;
    gap: 133px;
  }
`

const CardInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.25rem;

  padding: 1.25rem 1.25rem 0 1.25rem;

  ${({ theme }) => theme.media.lg} {
    gap: 3.75rem;
    padding: 2rem 0 2rem 2rem;

    max-width: 377px;
  }
`

const Cta = styled(ButtonLink)`
  max-width: fit-content;

  margin-top: 2rem;

  ${({ theme }) => theme.media.lg} {
    margin-top: 1.25rem;
  }
`

const ImageHolder = styled.div`
  overflow: hidden;

  height: 240px;
  width: 100%;

  padding: 1.25rem 1.25rem 1.25rem 1.25rem;

  ${({ theme }) => theme.media.lg} {
    padding: 0.5rem 0.5rem 0.5rem 0;
    height: 449px;
    width: 730px;
  }
`

const Image = styled(NextImage)`
  width: 100%;
  height: 100%;

  object-fit: cover;

  border-radius: 1rem;
`
