import { motion } from 'framer-motion'
import { useRouter } from 'next/router'
import React, { HTMLAttributes, useState } from 'react'
import styled from 'styled-components'

import { Container, Icon, IconHolder, Text, Tag, Theme } from 'common/UI'
import useDrawer from 'common/hooks/useDrawer'
import { FaqsStoryblok } from 'common/types'
import { addAlphaToColor, dashedLine } from 'common/utils/style'
import { StoryblokRichtext } from 'lib/types'
import { CMSRichText } from 'modules/shared'

import { Editable } from './Editable'

type FAQ = {
  question: string
  question_badge?: string
  answer: StoryblokRichtext
  video_id?: string
  defaultOpen?: boolean
} & HTMLAttributes<HTMLDivElement>

type Props = {
  block: FaqsStoryblok
}

const FAQ = ({
  defaultOpen = false,
  question,
  question_badge,
  answer,
  video_id,
  ...props
}: FAQ) => {
  const [open, setOpen] = useState(defaultOpen)

  return (
    <FAQHolder {...props}>
      <QuestionWrapper onClick={() => setOpen((prev) => !prev)}>
        <QuestionContentWrapper>
          <Text as="h3" variant="twenty/regular">
            {question}
          </Text>
          {question_badge && <Tag>{question_badge}</Tag>}
        </QuestionContentWrapper>
        <IconHolder css={{ flexShrink: 0, width: 28 }}>
          <Icon icon={open ? 'caret-up' : 'caret-down'} />
        </IconHolder>
      </QuestionWrapper>
      <Collapsable
        initial="closed"
        animate={open ? 'open' : 'closed'}
        variants={{ open: { height: 'auto' }, closed: { height: 0 } }}
        transition={{ type: 'spring', damping: 50, stiffness: 800 }}
      >
        <div css={{ padding: '1rem 0' }}>
          {answer && <CMSRichText richtext={answer} />}
          {video_id && (
            <FAQVideo
              id="ytplayer"
              src={`https://www.youtube.com/embed/${video_id}`}
              frameBorder="0"
              allowFullScreen
            />
          )}
        </div>
      </Collapsable>
    </FAQHolder>
  )
}

export const FAQS = ({ block, ...rest }: Props): JSX.Element => {
  const { drawer } = useDrawer()
  const isDrawer = !!drawer
  const { asPath } = useRouter()
  const { title, faqs, theme } = block

  return (
    <Theme colorTheme={theme || 'light'}>
      <Container variant={isDrawer ? 'full' : 'typography'} {...rest}>
        <Wrapper isDrawer={isDrawer}>
          <Heading as="h2" variant={isDrawer ? 'title/medium' : 'title/large'}>
            {title}
          </Heading>
          <div>
            {faqs.map((faq) => {
              return (
                <Editable key={faq._uid} block={faq}>
                  <FAQ
                    id={faq.anchor_id}
                    question={faq.question}
                    question_badge={faq.question_badge}
                    answer={faq.answer}
                    video_id={faq.video_id}
                    defaultOpen={
                      !!faq.anchor_id?.length &&
                      asPath.split('#')?.[1] === faq.anchor_id
                    }
                  />
                </Editable>
              )
            })}
          </div>
        </Wrapper>
      </Container>
    </Theme>
  )
}

const Wrapper = styled.div<{ isDrawer: boolean }>`
  padding: 2rem 0rem;

  ${({ theme }) => theme.media.lg} {
    padding: ${({ isDrawer }) => (isDrawer ? '2rem 0rem' : '3.75rem 0rem')};
  }
`

const Heading = styled(Text)`
  margin-bottom: 2rem;
`

const QuestionWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.25rem;
`
const QuestionContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 0.75rem;
`

const FAQHolder = styled.div`
  ${({ theme }) =>
    dashedLine('bottom', addAlphaToColor(theme.colors.foreground.default, 60))};
  padding-bottom: 1.25rem;
  scroll-margin-top: 5rem; // 4rem (Navbar size) + 1rem (little space to breathe)
`

const FAQVideo = styled.iframe`
  width: 100%;
  height: 280px;

  ${({ theme }) => theme.media.sm} {
    height: 395px;
  }

  ${({ theme }) => theme.media.lg} {
    height: 450px;
  }
`

const Collapsable = styled(motion.div)`
  overflow: hidden;
`
